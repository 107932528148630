import { ErrorHandler, Injectable } from "@angular/core";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (document.getElementById("error-info")) {
      // an error is already being shown
      return;
    }
    const stack = (
      String(error.stack)
      .split('\n')
      .filter(line => line.endsWith('[angular]'))
      .map(line => line.replace(location.origin + '/', '').split(':')[0])
      .join('\n')
    );
    (window as any).cogniErrorDialog(`${error.message}\n${stack}`);
  }
}
