import { BaseRequest } from '.'
import { BaseResponse, GenderCodesEnum, LanguageCodesEnum } from '../..'

export type GetSubscriberInformationRequest = BaseRequest & {
  subscriberId: string
}
export type GetSubscriberInformationResponse = BaseResponse & {
  subscriber?: {
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
    gender: GenderCodesEnum
    language: LanguageCodesEnum
  }
}

export type ReplaceEmailInStripeEventsRequest = BaseRequest & {
  originalEmail: string
  newEmail: string
}
export type ReplaceEmailInStripeEventsResponse = BaseResponse & {
  unrecognizedEventIds: string[]
}

export type TerminateSubscriptionRequest = BaseRequest & {
  subscriptionId: string
}
export type TerminateSubscriptionResponse = BaseResponse & {
  activeSubscriptionId?: string
}

export type CancelSubscriptionRequest = BaseRequest & {
  subscriptionId: string
}
export type CancelSubscriptionResponse = BaseResponse

export type GrantDashStudentSubscriptionRequest = BaseRequest & {
  email: string
  expiresAtDate: string
}
export type GrantDashStudentSubscriptionResponse = BaseResponse & {
  subscriptionId: string
}

export type ProcessExceptionStripeEventsRequest = BaseRequest
export type ProcessExceptionStripeEventsResponse = BaseResponse & {
  eventErrors: string[]
}
