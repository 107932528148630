import {
  ExportBigQueryTableToRtdbRequest,
  GetOpsUserViewRequest,
  GetSubscriberInformationRequest,
  GetUserInformationRequest,
  GrantPsychoeducationTrialRequest,
  ReadTableDataFromBigQueryRequest,
} from './request-types'

export enum OpsAuthenticatedRequestTypesEnum {
  GET_USER_VIEW = 'get-user-view',
  GET_SUBSCRIBER_INFORMATION = 'get-subscriber-information',
  GRANT_PSYEDU_TRIAL = 'grant-psyedu-trial',
  READ_TABLE_DATA_FROM_BIGQUERY = 'read-table-data-from-bigquery',
  EXPORT_BIGQUERY_TABLE_TO_RTDB = 'export-bigquery-table-to-rtdb',
  GET_USER_INFORMATION = 'get-user-information',
}

export type OpsAuthenticatedCFRequest = {
  type: OpsAuthenticatedRequestTypesEnum
  request?: GetOpsUserViewRequest
    | GetSubscriberInformationRequest
    | GrantPsychoeducationTrialRequest
    | ReadTableDataFromBigQueryRequest
    | ExportBigQueryTableToRtdbRequest
    | GetUserInformationRequest
}
