import { ContentAccessedEvent, GroupsEnum, LanguageCodesEnum, MinicardClickedEvent, MinicardIdsEnum, MuralTileClikedEvent, PageAccessedEvent, convertFromMinicardIdToMinicardName } from '../..'
import { AgentsEnum, EventLogProductsEnum, EventPageIdsEnum, EventsEnum, SidesEnum } from './event-logs.enums'

export const ContentEventLogFactory = {
  createMinicardClickedEvent: (data: {
    uid: string,
    minicardId: MinicardIdsEnum
  }): MinicardClickedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MINICARD_CLICKED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    id: convertFromMinicardIdToMinicardName(data.minicardId)
  }),

  createMuralTileClickedEvent: (data: {
    uid: string,
    tileId: string
  }): MuralTileClikedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MURAL_TILE_CLICKED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    tileId: data.tileId
  }),

  createContentAccessedEvent: (data : {
    product: EventLogProductsEnum
    uid: string
    contentId: string
    language: LanguageCodesEnum
    deviceLanguage: string
  }): ContentAccessedEvent => ({
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.CONTENT_ACCESSED_EVENT,
    timestamp: new Date(),
    uid: data.uid,
    language: data.language,
    deviceLanguage: data.deviceLanguage,
    contentId: data.contentId
  }),

  createPageAccessedEvent: (data : {
    product: EventLogProductsEnum
    uid: string
    pageId: EventPageIdsEnum
    language: LanguageCodesEnum
    deviceLanguage: string
  }): PageAccessedEvent => ({
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.PAGE_ACCESSED_EVENT,
    timestamp: new Date(),
    uid: data.uid,
    language: data.language,
    deviceLanguage: data.deviceLanguage,
    pageId: data.pageId
  }),
}
