export enum AppNotificationTypesEnum {
  CREATE_DTR = 'create-dtr',
  DISCOVER_MENU = 'discover-menu',

  SHARING_SHARE = 'sharing-share',
  SHARING_SEEN_COGNIS = 'sharing-seen-cognis',
  SHARING_TRIED_ACCESS = 'sharing-tried-access',
  SHARING_INVITATION_ACCEPTED = 'sharing-invitation-accepted',
  SHARING_INVITATION_REFUSED = 'sharing-invitation-refused',
  SHARING_ARCHIVED_BY_THERAPIST = 'sharing-archived-by-therapist',
  SHARING_RESHARE_REQUESTED = 'sharing-reshare-requested',

  CARD_DISCOVER_COGNI = 'card-discover-cogni',
  CARD_DISCOVER_DASHBOARD = 'card-discover-dashboard',
  CARD_GOT_THERAPIST = 'card-got-therapist',
  CARD_PERSONALIZE_EXPERIENCE = 'personalize-experience',
  CARD_REMINDER = 'reminder',

  CORP_ACCESS_REQUEST_ACCEPTED = 'corp-access-request-accepted',
  CORP_ACCESS_REQUEST_REFUSED = 'corp-access-request-refused',

  NEW_MODULE_AVAILABLE = 'new-module-available',
  NEW_SCALE_AVAILABLE = 'new-scale-available',
  PSYEDU_THIRD_WEEK_QUIZ = 'psyedu-third-week-quiz',

  EXTERNAL_URL = 'external-url',
  DEEPLINK = 'deeplink',
}

export enum PatientNotificationTypesEnum {
  SUICIDE_ALERT = 'SUICIDE-ALERT',
  INVITATION_RECEIVED = 'INVITATION-RECEIVED',
  PATIENT_UNSHARED = 'PATIENT-UNSHARED',
  UPDATES_IN_DTRS = 'UPDATES-IN-DTRS',
}

export enum AppNotificationGroupsEnum {
  ACTIONABLE = 'ACTIONABLE',
  INFORMATIONAL = 'INFORMATIONAL'
}

export enum AppNotificationStatusesEnum {
  NEW = 'S0-NEW',
  ACTION_PENDING = 'S1-ACTION-PENDING',
  SEEN = 'S2-SEEN',
  TERMINATED = 'S3-TERMINATED'
}

export enum PushNotificationStatuses {
  SENT = 'SENT',
  ACTION_PERFORMED = 'ACTION_PERFORMED',
  DISCARDED = 'DISCARDED'
}

export enum PushNotificationDiscardReasonsEnum {
  APP_FOREGROUND = 'APP-FOREGROUND',
  EXPIRED = 'EXPIRED',
  REPLACED = 'REPLACED',
  UNSUPPORTED = 'UNSUPPORTED'
}

//lowercase to match Capacitor codes
export enum PushPermissionStatusesEnum {
  PENDING = 'pending',
  GRANTED = 'granted',
  DENIED = 'denied'
}

//lowercase to match Capacitor codes
export enum ReminderPermissionStatusesEnum {
  PENDING = 'pending',
  GRANTED = 'granted',
  DENIED = 'denied',
  DISABLED = 'disabled',
  SCHEDULED = 'scheduled'
}

export enum BatteryChoicesEnum {
  OPTIMIZE = 'optimize',
  UNOPTIMIZE = 'unoptimize',
  IGNORE = 'ignore'
}

export enum ReattemptFrequenciesEnum {
  RETRY_IN_7_DAYS = 'RETRY-IN-7-DAYS',
  RETRY_IN_14_DAYS = 'RETRY-IN-14-DAYS',
  RETRY_IN_28_DAYS = 'RETRY-IN-28-DAYS',
  RETRY_IN_56_DAYS = 'RETRY-IN-56-DAYS',
  RETRY_IN_112_DAYS = 'RETRY-IN-112-DAYS'
}

export enum DashNoticesUponLaunchEnum {
  NEW_SUBSCRIBER = 'NEW-SUBSCRIBER',
  SUBSCRIPTION_CANCELLED = 'SUBSCRIPTION-CANCELLED',
  COGN_HAS_CHANGED = 'COGNI-HAS-CHANGED',
  WELCOME_NEW_USER = 'WELCOME-NEW-USER',
  NONE = 'NONE'
}