import {
  GroupsEnum,
  AppNotificationTypesEnum,
  ShiftsEnum,
  PushPermissionStatusesEnum,
  ReminderPermissionStatusesEnum
} from '../..'

import {
  AgentsEnum,
  EventsEnum,
  EventLogProductsEnum,
  SidesEnum,
  PushConfigurationEvent,
  PushReceivedEvent,
  PushClickedEvent,
  ReminderConfigurationEvent,
  ReminderReceivedEvent,
  ReminderClickedEvent,
  MenuNotificationActionTakenEvent,
  MenuNotificationIgnoredEvent,
} from '..'

export const NotificationEventLogFactory = {

  createPushConfigurationEvent: (data: {
    uid: string
    deviceId: string
    status: PushPermissionStatusesEnum
  }): PushConfigurationEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.PUSH_CONFIGURED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    deviceId: data.deviceId,
    status: data.status
  }),

  createPushReceivedEvent: (data: {
    uid: string
    pushId: string
    deviceId: string
    type: AppNotificationTypesEnum
    signedIn: boolean
  }): PushReceivedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.PUSH_RECEIVED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    id: data.pushId,
    deviceId: data.deviceId,
    type: data.type,
    signedIn: data.signedIn
  }),

  createPushClickedEvent: (data: {
    uid: string
    pushId: string
    deviceId: string
    type: AppNotificationTypesEnum
    signedIn: boolean
  }): PushClickedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.PUSH_CLICKED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    id: data.pushId,
    deviceId: data.deviceId,
    type: data.type,
    signedIn: data.signedIn
  }),

  createReminderConfigurationEvent: (data: {
    uid: string
    deviceId: string
    status: ReminderPermissionStatusesEnum
  }): ReminderConfigurationEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.REMINDER_CONFIGURED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    deviceId: data.deviceId,
    status: data.status
  }),


  createReminderReceivedEvent: (data: {
    uid: string
    deviceId: string
    signedIn: boolean
    messageSet: string
    shift: ShiftsEnum
    messageIndex: number
  }): ReminderReceivedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.REMINDER_RECEIVED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    deviceId: data.deviceId,
    signedIn: data.signedIn,
    messageSet: data.messageSet,
    shift: data.shift || null,
    messageIndex: data.messageIndex || null
  }),

  createReminderClickedEvent: (data: {
    uid: string
    deviceId: string
    signedIn: boolean
    messageSet: string
    shift: ShiftsEnum
    messageIndex: number
  }): ReminderClickedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.REMINDER_CLICKED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    deviceId: data.deviceId,
    signedIn: data.signedIn,
    messageSet: data.messageSet,
    shift: data.shift || null,
    messageIndex: data.messageIndex || null
  }),

  createAppNotificationActionTakenEvent: (data: {
    product: EventLogProductsEnum
    uid: string
    appNotificationType: AppNotificationTypesEnum,
    notificationId: string
  }): MenuNotificationActionTakenEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MENU_NOTIFICATION_ACTION_TAKEN,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    appNotificationType: data.appNotificationType,
    notificationId: data.appNotificationType
  }),

  createAppNotificationIgnoredEvent: (data: {
    product: EventLogProductsEnum
    uid: string
    appNotificationType: AppNotificationTypesEnum,
    notificationId: string
  }): MenuNotificationIgnoredEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MENU_NOTIFICATION_IGNORED,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    appNotificationType: data.appNotificationType,
    notificationId: data.appNotificationType
  }),

}
