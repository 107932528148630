import { GenderCodesEnum } from 'src/libs';
import { ProfessionalId, ProfessionalInformation } from 'src/libs';


export interface PersonalInformation {
  firstName: string;
  lastName: string;
  gender: GenderCodesEnum;
  professionalId?: ProfessionalId;
  professionalInformation?: ProfessionalInformation;
}
