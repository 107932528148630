import {
  LanguageCodesEnum,
  ProductsEnum,
  GroupsEnum
} from '../..'

import {
  AccountCreatedEvent,
  PasswordRecoveryAttemptedEvent,
  PasswordResetEvent,
  AgentsEnum,
  EventsEnum,
  EventLogProductsEnum,
  SidesEnum,
  ResultsEnum,
  AccountVerifiedEvent,
  AccountDeletedEvent,
  DeletionReasonsEnum
} from '..'

export const AccountEventLogFactory = {
  createAccountCreatedByAppEvent: (data: {
    timestamp: Date,
    uid: string,
    email: string,
    language: LanguageCodesEnum
  }): AccountCreatedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.ACCOUNT_CREATED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    timestamp: data.timestamp,
    uid: data.uid,
    email: data.email.toLowerCase(),
    language: data.language,
    signupSource: ProductsEnum.COGNI_APP,
  }),

  createAccountCreatedByDashEvent: (data: {
    timestamp: Date,
    uid: string,
    email: string,
    language: LanguageCodesEnum,
    signupSource: ProductsEnum,
  }): AccountCreatedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.ACCOUNT_CREATED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    timestamp: data.timestamp,
    uid: data.uid,
    email: data.email.toLowerCase(),
    language: data.language,
    signupSource: data.signupSource
  }),

  createAccountCreatedByCorpDashEvent: (data: {
    timestamp: Date,
    uid: string,
    email: string,
    language: LanguageCodesEnum
  }): AccountCreatedEvent => ({
    agent: AgentsEnum.SYSTEM,
    event: EventsEnum.ACCOUNT_CREATED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.CORP_DASHBOARD,
    side: SidesEnum.SERVER,
    timestamp: data.timestamp,
    uid: data.uid,
    email: data.email.toLowerCase(),
    language: data.language,
    signupSource: ProductsEnum.COGNI_CORP_DASHBOARD,
  }),

  createPasswordRecoveryAttemptedEvent: (data: {
    email: string,
    product: EventLogProductsEnum,
    deviceId?: string,
    errorReason?: string
  }): PasswordRecoveryAttemptedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.PASSWORD_RECOVERY_ATTEMPTED,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    email: data.email.toLowerCase(),
    deviceId: data.deviceId || null,
    result: data.errorReason ? ResultsEnum.ERROR : ResultsEnum.OK,
    errorReason: data.errorReason || null
  }),

  createPasswordResetEvent: (data: {
    email: string,
    product: EventLogProductsEnum,
    deviceId?: string
  }): PasswordResetEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.PASSWORD_RESET,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    email: data.email.toLowerCase(),
    deviceId: data.deviceId || null,
    result: ResultsEnum.OK
  }),

  createAccountVerifiedEvent: (data: {
    uid: string,
    product: EventLogProductsEnum,
    timestamp: Date
  }): AccountVerifiedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.ACCOUNT_VERIFIED,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: data.timestamp,
    uid: data.uid
  }),

  createAccountDeletedEvent: (data: {
    uid: string,
    email: string,
    product: EventLogProductsEnum,
    deletionReason: DeletionReasonsEnum,
    timestamp: Date
  }): AccountDeletedEvent => ({
    agent: data.deletionReason === DeletionReasonsEnum.EMAIL_UNVERIFIED ? AgentsEnum.SYSTEM : AgentsEnum.USER,
    event: EventsEnum.ACCOUNT_DELETED,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: data.deletionReason === DeletionReasonsEnum.EMAIL_UNVERIFIED ? SidesEnum.SERVER : SidesEnum.CLIENT_UX,
    timestamp: data.timestamp,
    uid: data.uid,
    email: data.email,
    deletionReason: data.deletionReason
  })
}