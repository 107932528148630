export enum MatchmakingProfessionCodesEnum {
  PSYCHIATRY = 'PSYCHIATRY',
  PSYCHOLOGY = 'PSYCHOLOGY'
}

export enum MatchmakingProfessionFiltersEnum {
  PSYCHIATRY = 'PSYCHIATRY',
  PSYCHOLOGY = 'PSYCHOLOGY',
  PSYT_AND_PSYG = 'PSYT_AND_PSYG'
}

export enum MatchmakingSearchTypesEnum {
  STANDARD = 'STANDARD',
  KEYWORD_BASED = 'KEYWORD-BASED',
}

export enum MatchmakingSearchResultTypesEnum {
  MATCHED = 'MATCHED',
  UNMATCHED = 'UNMATCHED',
}

export enum MatchmakingOrderingCriteriasEnum {
  PRICE_RANGE_ASCENDING = 'PRICE-RANGE-ASCENDING',
  PRICE_RANGE_DESCENDING = 'PRICE-RANGE-DESCENDING',
  SPECIALISTS_FIRST = 'SPECIALISTS-FIRST',
}

export enum MatchmakingTermsVersionCodesEnum {
  DECEMBER_2023 = '2023-12-15T00:00:00.000Z'
}

export enum MatchmakingApproachCodesEnum {
  ACT = 'ACT',
  BEHAVIOR_ANALYSIS  = 'BEHAVIOR_ANALYSIS',
  CBT = 'CBT',
  COGNITIVE_THERAPIES = 'COGNITIVE_THERAPIES',
  DBT = 'DBT',
  PSYCHOANALYSIS = 'PSYCHOANALYSIS',
}

export enum MatchmakingFocusCodesEnum {
  ADHD = 'ADHD',
  ANXIETY = 'ANXIETY',
  BORDERLINE = 'BORDERLINE',
  BURNOUT = 'BURNOUT',
  DEPRESSION = 'DEPRESSION',
  MOOD_SWINGS = 'MOOD_SWINGS',
  OBESITY_WEIGHT_LOSS = 'OBESITY_AND_WEIGHT_LOSS',
  RELATIONSHIPS = 'RELATIONSHIPS',
}

export enum MatchmakingSpecialtyCodesEnum {
  ACT = 'ACT',
  CBT = 'CBT',
  CBT_FOR_PRO = 'CBT_FOR_PROFESSIONALS',
  NEURODIVERGENCE = 'NEURODIVERGENCE',
  NEUROPSYCHOLOGY = 'NEUROPSYCHOLOGY',
  PSYCHOPATHOLOGY = 'PSYCHOPATOLOGY',
  DOCTORATE = 'DOCTORATE',
  MS_PSYCHOLOGY = 'MS_PSYCHOLOGY',
  PHD_HEALTH_SCIENCES = 'PHD_HEALTH_SCIENCES',
  PSYCHOLOGICAL_EVALUATION = 'PSYCHOLOGICAL_EVALUATION',
}

export enum MatchmakingTargetPublicCodesEnum {
  ADULTS = 'ADULTS',
  CHILDREN = 'CHILDREN',
  ELDERLIES = 'ELDERLIES',
  TEENAGERS = 'TEENAGERS',
  WOMEN = 'WOMEN',
}

export const matchmakingProblemCodes = [
  'UNKNOWN',
  MatchmakingFocusCodesEnum.ANXIETY,
  MatchmakingFocusCodesEnum.DEPRESSION,
  MatchmakingFocusCodesEnum.BORDERLINE,
  MatchmakingFocusCodesEnum.BURNOUT,
  MatchmakingFocusCodesEnum.ADHD,
  MatchmakingFocusCodesEnum.RELATIONSHIPS,
  MatchmakingFocusCodesEnum.OBESITY_WEIGHT_LOSS,
  MatchmakingFocusCodesEnum.MOOD_SWINGS,
  'OTHER'
]

export const matchmakingReasonCodes = [
  'UNKNOWN',
  'PERSONAL_DEVELOPMENT',
  'SOLVE_MILD_PROBLEM',
  'SOLVE_SEVERE_PROBLEM',
  'URGENCY',
  'OTHER'
]

export const matchmakingProfessionCodes = [
  MatchmakingProfessionFiltersEnum.PSYCHOLOGY,
  //MatchmakingProfessionFiltersEnum.PSYCHIATRY,
  //MatchmakingProfessionFiltersEnum.PSYT_AND_PSYG,
]
