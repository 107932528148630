import { EventLogProductsEnum, ProductsEnum } from 'src/libs';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  message: 'DEV Environment',
  domainConfigs: {
    productsEnum: ProductsEnum.COGNI_DASHBOARD,
    eventLogProductsEnum: EventLogProductsEnum.DASHBOARD
  },
  firebase: {
    apiKey: 'AIzaSyA9MHtCklUe8XVnwgP-M69msUkL84FRXSs',
    authDomain: 'cogni-ionic-dev.firebaseapp.com',
    databaseURL: 'https://cogni-ionic-dev.firebaseio.com',
    projectId: 'cogni-ionic-dev',
    storageBucket: 'cogni-ionic-dev.appspot.com',
    messagingSenderId: '1037140758256',
    appId: '1:1037140758256:web:94071f8b7a09ecae9b7c35',
    measurementId: 'G-6JTLV49139'
  },
  enablePersistenceForFirestore: false, // disabled for browser
  urls: {
    termsOfUse: 'https://cogni-ionic-static-content.web.app/cogni-app-terms/',
    privacyPolicy: 'https://cogni-ionic-static-content.web.app/privacy-policy/',
    faqBaseUrl: 'https://cogniapp.com/dc/faq/dash',
    rootUrl: 'https://cogni-dev-painel.cogniapp.com'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
