import {
  applyActionCode,
  Auth,
  confirmPasswordReset,
  EmailAuthProvider,
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signOut,
  updateEmail,
  updatePassword,
  updateProfile,
  User,
  verifyPasswordResetCode,
} from 'firebase/auth'
import {
  FirebaseInitializer,
  FirebaseInitializerStatusesEnum,
} from './firebase-initializer'
import { getApp } from 'firebase/app'
import { BehaviorSubject } from 'rxjs'
import { AuthStatusesEnum } from '..'
import { Logger } from '..'


export class AuthClient {

  #auth: Auth

  #authState = AuthStatusesEnum.UNKNOWN;
  #authStateSubject = new BehaviorSubject<AuthStatusesEnum>(AuthStatusesEnum.UNKNOWN)
  public readonly authState$ = this.#authStateSubject.asObservable();

  constructor(firebaseInitializer: FirebaseInitializer) {
    firebaseInitializer.initializationStatus$.subscribe(state => {
      if (state === FirebaseInitializerStatusesEnum.INITIALIZED) {
        this.#auth = getAuth(getApp())
        onAuthStateChanged(this.#auth, user => {
          if (user) {
            this.#authState = AuthStatusesEnum.AUTHENTICATED;
          } else {
            this.#authState = AuthStatusesEnum.UNAUTHENTICATED;
          }
          this.#authStateSubject.next(this.#authState)
        })
      }
    })
  }

  async signIn(email: string, password: string): Promise<string> {
    try {
      await signInWithEmailAndPassword(this.#auth, email, password)
      return this.currentUser.uid
    } catch (error) {
      Logger.error(error)
      throw error
    }
  }

  async signOut() {
    await signOut(this.#auth)
  }

  get currentUser(): User {
    return this.#auth.currentUser
  }

  async reauthenticateUser(password: string) {
    try {
      const credential = EmailAuthProvider.credential(this.#auth.currentUser.email, password)
      await reauthenticateWithCredential(this.#auth.currentUser, credential)
    } catch (error) {
      Logger.error(error)
      throw error
    }
  }

  async updateUserPassword(password: string) {
    try {
      await updatePassword(this.currentUser, password)
    } catch (error) {
      Logger.error(error)
      throw error
    }
  }

  async updateUserEmail(newEmail: string) {
    if (newEmail === this.email) {
      throw { code: 'auth/email-equals-current' }
    }
    try {
      await updateEmail(this.currentUser, newEmail)
    } catch (error) {
      Logger.error(error)
      throw error
    }
  }

  async updateDisplayName(newName: string) {
    try {
      await updateProfile(this.currentUser, { displayName: newName })
    } catch (error) {
      Logger.error(error)
      throw error
    }
  }

  get authStateReady() {
    return this.#authState !== AuthStatusesEnum.UNKNOWN;
  }

  get signedIn() {
    return this.#auth.currentUser ? true : false
  }

  get uid() {
    return this.currentUser?.uid
  }

  get displayName() {
    return this.currentUser?.displayName
  }

  get email() {
    return this.currentUser?.email
  }

  get emailVerified() {
    return this.currentUser?.emailVerified
  }

  get accountCreationDate() {
    if (this.currentUser) {
      return new Date(this.currentUser.metadata.creationTime)
    }
    return undefined
  }

  get customClaims() {
    const customAttributes = (this.currentUser as any)?.reloadUserInfo.customAttributes
    if (customAttributes) {
      return JSON.parse(customAttributes)
    }
    return undefined
  }

  async reloadCurrentUser() {
    if (this.currentUser) {
      await this.currentUser.reload()
      return this.currentUser
    }
    return undefined
  }

  verifyPasswordResetCode(code: string) {
    return verifyPasswordResetCode(this.#auth, code)
  }

  confirmPasswordReset(code: string, newPassword: string) {
    return confirmPasswordReset(this.#auth, code, newPassword)
  }

  applyActionCode(code: string) {
    return applyActionCode(this.#auth, code)
  }

  signInWithEmailLink(email: string, emailLink: string) {
    return signInWithEmailLink(this.#auth, email, emailLink)
  }

  isSignInWithEmailLink(emailLink: string) {
    return isSignInWithEmailLink(this.#auth, emailLink)
  }
}
