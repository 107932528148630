import { LanguageCodesEnum } from 'src/libs';

export type SupportedLocale = {
  code: LanguageCodesEnum;
  defaultFor: string;
  dateFns: Locale;
  calendarDateFormat: string;
  shortDateFormat: string;
  longDateFormat: string;
  timeFormat: string;
  datetimeFormat: string;
  dayNamesForCalendar: string[];
  monthNames: string[];
  monthShortNames: string[];
  name: string;
  hourCycle: string;
  onlyNumbersDateFormat: string;
  onlyNumbersDatePlaceholder: string[];
  onlyNumbersDateCaption: string[];
};
