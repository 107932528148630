import {
  CancelSubscriptionRequest,
  DeleteUserRequest,
  ExportBigQueryTableToRtdbRequest,
  GetOpsUserViewRequest,
  GetSubscriberInformationRequest,
  GetUserInformationExRequest,
  GetUserInformationRequest,
  GrantDashStudentSubscriptionRequest,
  GrantPsychoeducationTrialRequest,
  ProcessExceptionStripeEventsRequest,
  ReadTableDataFromBigQueryRequest,
  ReplaceEmailInStripeEventsRequest,
  TerminateSubscriptionRequest,
} from './request-types'

export enum OpsAuthenticatedRequestTypesEnum {
  GET_USER_VIEW = 'get-user-view',
  GET_SUBSCRIBER_INFORMATION = 'get-subscriber-information',
  GRANT_PSYEDU_TRIAL = 'grant-psyedu-trial',
  READ_TABLE_DATA_FROM_BIGQUERY = 'read-table-data-from-bigquery',
  EXPORT_BIGQUERY_TABLE_TO_RTDB = 'export-bigquery-table-to-rtdb',
  GET_USER_INFORMATION = 'get-user-information',
  GET_USER_INFORMATION_EX = 'get-user-information-ex',
  REPLACE_EMAIL_IN_STRIPE_EVENTS = 'replace-email-in-stripe-events',
  TERMINATE_SUBSCRIPTION = 'terminate-subscription',
  CANCEL_SUBSCRIPTION = 'cancel-subscription',
  GRANT_DASH_STUDENT_SUBSCRIPTION = 'grant-dash-student-subscription',
  PROCESS_EXCEPTION_STRIPE_EVENTS = 'process-exception-stripe-events',
  DELETE_USER = 'delete-user',
}

export type OpsAuthenticatedCFRequest = {
  type: OpsAuthenticatedRequestTypesEnum
  request?: GetOpsUserViewRequest
    | GetSubscriberInformationRequest
    | GrantPsychoeducationTrialRequest
    | ReadTableDataFromBigQueryRequest
    | ExportBigQueryTableToRtdbRequest
    | GetUserInformationRequest
    | GetUserInformationExRequest
    | ReplaceEmailInStripeEventsRequest
    | TerminateSubscriptionRequest
    | CancelSubscriptionRequest
    | GrantDashStudentSubscriptionRequest
    | ProcessExceptionStripeEventsRequest
    | DeleteUserRequest
}
