import { GroupsEnum } from '../..'
import { InterconnectionEvent, PatientArchivedEvent, RecordSharingEvent } from './event-logs.types'
import { AgentsEnum, EventLogProductsEnum, EventsEnum, SidesEnum } from './event-logs.enums'
import { InterconnectionDBDocument } from '../db-types'

export const RecordSharingEventLogFactory = {
  createInterconnectionCreatedEvent: (data: {
    interconnection: InterconnectionDBDocument
  }): InterconnectionEvent => ({
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.SERVER,
    agent: AgentsEnum.SYSTEM,
    event: EventsEnum.INTERCONNECTION_CREATED,
    timestamp: data.interconnection.startedAtDate,
    patientId: data.interconnection.patientId,
    therapistId: data.interconnection.therapistId,
    interconnectionId: data.interconnection.id,
  }),

  createInterconnectionTerminatedEvent: (data: {
    interconnection: InterconnectionDBDocument
  }): InterconnectionEvent => ({
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.SERVER,
    agent: AgentsEnum.SYSTEM,
    event: EventsEnum.INTERCONNECTION_TERMINATED,
    timestamp: data.interconnection.terminatedAtDate!,
    patientId: data.interconnection.patientId,
    therapistId: data.interconnection.therapistId,
    interconnectionId: data.interconnection.id,
  }),

  createRecordSharingStoppedEvent: (data: {
    interconnection: InterconnectionDBDocument
  }): RecordSharingEvent => ({
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.RECORD_SHARING_STOPPED,
    timestamp: data.interconnection.terminatedAtDate!,
    patientId: data.interconnection.patientId,
    therapistId: data.interconnection.therapistId,
    interconnectionId: data.interconnection.id,
  }),

  createPatientArchivedEvent: (data: {
    interconnection: InterconnectionDBDocument
  }): PatientArchivedEvent => ({
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.PATIENT_ARCHIVED,
    timestamp: data.interconnection.terminatedAtDate!,
    patientId: data.interconnection.patientId,
    therapistId: data.interconnection.therapistId,
    interconnectionId: data.interconnection.id,
  }),
}