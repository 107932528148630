import { Injectable } from '@angular/core';
import {
  AcceptPatientInvitationRequest,
  DashAuthenticatedRequestTypesEnum,
  ProductsEnum,
  InvitationDBDocument,
  PatientDBDocument,
  RefusePatientInvitationRequest,
  StatusEnum,
  FirestoreClient,
  AcceptPatientInvitationResponse,
  ArchivePatientRequest,
  ArchivePatientResponse,
  InvitePatientRequest,
  PatientNotificationTypesEnum,
  ErrorsEnum
} from 'src/libs';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { PatientFactory, PatientModel, PatientModelStatusesEnum, PatientTypesEnum } from '..';
import { CloudFunctionsService } from 'src/app/core';
import { CollectionReference, query, where } from 'firebase/firestore';

const FREEMIUM_PATIENT_QUOTA = 2;

@Injectable({
  providedIn: 'root'
})
export class PatientsService {

  private patients: PatientModel[] = [];
  private patientsSubject = new BehaviorSubject<PatientModel[]>([]);
  public readonly patients$ = this.patientsSubject.asObservable();

  private currentPatientSubject = new BehaviorSubject<PatientModel>(undefined);
  public readonly currentPatient$ = this.currentPatientSubject.asObservable();

  private timer: NodeJS.Timeout;

  constructor(
    private firestore: FirestoreClient,
    private functionsService: CloudFunctionsService,
  ) { }

  async listenToCollections(uid: string) {
    await this.loadItems(uid);
  }
  
  stopListeningToCollections() {
    clearTimeout(this.timer);
    this.clear();
  }
  
  selectPatient(patientId: string | undefined) {
    const previouslySelected = this.patients.filter(pat => pat.selected === true);
    if (previouslySelected?.length === 1) {
      previouslySelected[0].selected = false;
    }
    const newlySelected = this.patients.filter(pat => pat.patientId === patientId);
    if (newlySelected?.length === 1) {
      newlySelected[0].selected = true;
      this.currentPatientSubject.next(newlySelected[0]);
    }
    else {
      this.currentPatientSubject.next(undefined);
    }
  }

  get activePatients() {
    return this.patients.filter(patient => patient.status === PatientModelStatusesEnum.ACTIVE);
  }

  get freemiumPatientQuota() {
    return FREEMIUM_PATIENT_QUOTA;
  }

  isExceedingPatientQuota() {
    if (this.activePatients.length > FREEMIUM_PATIENT_QUOTA) {
      return true;
    }
    return false;
  }

  willExceedPatientQuotaOnAcceptInvitation() {
    if (this.activePatients.length + 1 > FREEMIUM_PATIENT_QUOTA) {
      return true;
    }
    return false;
  }

  private async loadItems(uid: string) {
    await this.loadPatients(uid);
    await this.loadInvitations(uid);
    this.patientsSubject.next(this.patients);
    this.scheduleDataRefresh(this, uid);
  }

  private async scheduleDataRefresh(service: PatientsService, uid: string) {
    this.timer = setTimeout( async () => {
      service.loadItems(uid)
    }, 15 * 60  * 1000); // every 15 minutes
  }

  private processInvitations(invitations: InvitationDBDocument[]) {
    invitations.forEach( (item: InvitationDBDocument) => {
      const index = this.patientAlreadyInList(item.patientId);
      if (index === -1) {
        const invitation = PatientFactory.createInvitation(item);
        this.patients.push(invitation);
      }
      else {
        const invitation = PatientFactory.updateInvitation(this.patients.at(index), item);
        this.replaceIfDifferent(index, invitation);
      }
    });
  }
  
  private processPatients(patients: PatientDBDocument[]) {
    patients.forEach( (item: PatientDBDocument) => {
      const index = this.patientAlreadyInList(item.patientId);
      if (index === -1) {
        const patient = PatientFactory.createPatient(item)
        this.patients.push(patient);
      }
      else if (this.patients.at(index).type !== PatientTypesEnum.INVITATION) {
        const patient = PatientFactory.updatePatient(this.patients.at(index), item);
        this.replaceIfDifferent(index, patient);
      }
    });
  }

  async acceptInvitation(invitationId: string) {
    const request: AcceptPatientInvitationRequest = {
      callerId: ProductsEnum.COGNI_DASHBOARD,
      invitationId
    };
    const response = await this.functionsService.dashAuthenticatedCall({
      type: DashAuthenticatedRequestTypesEnum.ACCEPT_PATIENT_INVITATION,
      request: request
    });
    if (response.status === StatusEnum.OK) {
      const index = this.patientAlreadyInList((response as AcceptPatientInvitationResponse).patient.patientId)
      if (index > -1) {
        const patient = PatientFactory.updatePatient(this.patients.at(index), (response as AcceptPatientInvitationResponse).patient);
        patient.notifications = patient.notifications.filter(notification => notification != PatientNotificationTypesEnum.INVITATION_RECEIVED);
        this.replaceIfDifferent(index, patient);
      }
      this.selectPatient((response as AcceptPatientInvitationResponse).patient.patientId);
    }
    return response;
  }

  async refuseInvitation(invitationId: string) {
    const request: RefusePatientInvitationRequest = {
      callerId: ProductsEnum.COGNI_DASHBOARD,
      invitationId
    };
    const response = await this.functionsService.dashAuthenticatedCall({
      type: DashAuthenticatedRequestTypesEnum.REFUSE_PATIENT_INVITATION,
      request: request
    });
    if (response.status === StatusEnum.OK) {
      this.removeInvitation(invitationId);
      this.currentPatientSubject.next(undefined);
    }
    return response;
  }

  async archivePatient(patient: PatientModel) {
    const request: ArchivePatientRequest = {
      callerId: ProductsEnum.COGNI_DASHBOARD,
      interconnectionId: patient.interconnectionId 
    };
    const response = await this.functionsService.dashAuthenticatedCall({
      type: DashAuthenticatedRequestTypesEnum.ARCHIVE_PATIENT,
      request: request
    });
    if (response.status === StatusEnum.OK) {
      const index = this.patientAlreadyInList(patient.patientId);
      if (index > -1) {
        const patient = PatientFactory.updatePatient(this.patients.at(index), (response as ArchivePatientResponse).patient);
        this.replaceIfDifferent(index, patient);
        return { status: response.status, patient: patient };
      }
    }
    return { status: response.status, error: response.error };
  }

  async reinvitePatient(patient: PatientModel) {
    const request: InvitePatientRequest = {
      callerId: ProductsEnum.COGNI_DASHBOARD,
      patientId: patient.patientId
    };
    const response = await this.functionsService.dashAuthenticatedCall({
      type: DashAuthenticatedRequestTypesEnum.INVITE_PATIENT,
      request: request
    });
    if (response.status === StatusEnum.OK) {
      const index = this.patientAlreadyInList(patient.patientId);
      if (index > -1) {
        const patient = PatientFactory.updatePatient(this.patients.at(index), (response as ArchivePatientResponse).patient);
        this.replaceIfDifferent(index, patient);
        this.selectPatient(patient.patientId);
        return { status: response.status, patient: patient };
      }
    }
    return { status: response.status, error: response.error };
  }

  private clear() {
    this.patients = [];
    this.patientsSubject.next(this.patients);
  }

  private patientAlreadyInList(patientId: string) {
    return this.patients.findIndex(patient => patient.patientId === patientId);
  }

  private removeInvitation(invitationId: string) {
    const index = this.patients.findIndex(patient => patient.invitationId === invitationId);
    this.patients.splice(index, 1);
  }

  private replaceIfDifferent(index: number, patient: PatientModel) {
    if (!_.isEqual(this.patients.at(index), patient)) {
      this.patients.splice(index, 1, patient);
    }
  }

  private async loadPatients(therapistId: string) {
    const patients: PatientDBDocument[] = [];
    const q = query(this.getPatientsCollRef(therapistId));
    const querySnapshot = await this.firestore.getColl(q);
    if (querySnapshot) {
      Object.entries(querySnapshot).map( (document: any) => {
        if (document) {
          const patient = document[1].data();
          this.firestore.fromTimestampToDateByDocument(patient, 'AtDate')
          patients.push(patient as PatientDBDocument);
        }
      });
    }
    this.processPatients(patients);
  }

  private async loadInvitations(therapistId: string) {
    const invitations: InvitationDBDocument[] = [];
    const q = query(this.getInvitationsCollRef(), where('therapistId', '==', therapistId));
    const querySnapshot = await this.firestore.getColl(q);
    if (querySnapshot) {
      Object.entries(querySnapshot).map( (document: any) => {
        if (document) {
          const invitation = document[1].data();
          this.firestore.fromTimestampToDateByDocument(invitation, 'AtDate')
          invitations.push(invitation as InvitationDBDocument);
        }
      });
    }
    this.processInvitations(invitations);
  }

  private getPatientsCollRef(uid: string): CollectionReference {
    return this.firestore.collRef(`${this.clinicalRecordsCollId}/${uid}/${this.patientsCollGroupId}`)
  }

  private getInvitationsCollRef(): CollectionReference {
    return this.firestore.collRef(this.invitationsRequestsCollId)
  }

  private clinicalRecordsCollId = '/clinicalRecords'
  private invitationsRequestsCollId = '/invitations'
  // collection groups MUST NOT contain a leading '/'
  private patientsCollGroupId = 'patients'
}
