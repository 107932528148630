import {
  GenderCodesEnum,
  LanguageCodesEnum,
  MobilePhoneNumber,
  ProfessionalId,
  ProfessionalInformation,
} from 'src/libs'

export type UserIdentityModel = {
  readonly uid: string;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly cognicode: string | null;
  readonly mobilePhoneNumber: MobilePhoneNumber | null;
}

export type UserProfessionalInformationModel = {
  readonly professionalId: ProfessionalId,
  readonly professionalInformation: ProfessionalInformation
}

export type UserOptionsModel = {
  readonly gender: GenderCodesEnum;
  readonly language: LanguageCodesEnum;
}

export type UserGuardsModel = {
  readonly dashTermsVersionAccepted: string | null;
  readonly privacyPolicyVersionAccepted: string | null;
}

export type DashUserProfileModel = {
  readonly identity: UserIdentityModel;
  readonly options: UserOptionsModel;
  readonly guards: UserGuardsModel;
  readonly professionalInformation: UserProfessionalInformationModel;
}
