import { ArchiveReasonsEnum, GenderCodesEnum, PatientNotificationTypesEnum } from '../../../../libs';
import { PatientModelStatusesEnum, PatientTypesEnum, ViewOrderEnum } from '..';

export type PatientModel = {
  type: PatientTypesEnum
  status: PatientModelStatusesEnum
  firstName: string
  lastName: string
  gender: GenderCodesEnum
  selected: boolean
  invitationId: string | null
  patientId: string | null
  therapistId: string | null
  interconnectionId: string | null
  lastDTREditionAtDate: Date | null
  archivedAtDate: Date | null
  archiveReason: ArchiveReasonsEnum | null
  notifications: PatientNotificationTypesEnum[]
  headline?: string
  viewOrder: ViewOrderEnum
}
