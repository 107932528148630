import { Component } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { AuthStatusesEnum } from 'src/libs';
import { UserService } from './features/user';
import { EventLogService, pageLinks } from './core';
import { AppService } from './features/app/services';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  constructor(private platform: Platform,
    private userService: UserService,
    private appService: AppService,
    private eventLogService: EventLogService,
    private navController: NavController,
  ) {
   if (window.location.pathname !== '/action'
        && window.location.pathname !== '/matchmaking'
        && window.location.pathname !== '/manifesto'
        && window.location.pathname !== '/matchsales'
        && window.location.pathname !== '/match'
        && window.location.pathname !== '/sales') {
      const unsubscribe = this.userService.auth.authState$.subscribe( async (state) => {
        if (state !== AuthStatusesEnum.UNKNOWN) {
          unsubscribe.unsubscribe();
          this.initializeApp(state);
        }
      });
    }
  }

  initializeApp(state: AuthStatusesEnum) {
    this.platform.ready().then(async () => {
      if (state === AuthStatusesEnum.AUTHENTICATED && this.userService.auth.emailVerified) {
        await this.eventLogService.insertAuthenticatedEvent();
        await this.appService.initializeAppForAuthenticatedUser();
        await this.navController.navigateRoot(pageLinks.main);
      } else if (state === AuthStatusesEnum.UNAUTHENTICATED) {
        await this.navController.navigateRoot(pageLinks.coverScreen);
      }
    });
  }
}
