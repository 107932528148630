import {
  GroupsEnum,
  PlatformsEnum,
  LanguageCodesEnum
} from '../..'

import {
  AgentsEnum,
  EventsEnum,
  EventLogProductsEnum,
  SidesEnum,
  HelpTipClickedEvent,
  FaqClickedEvent,
  WhatsAppClickedEvent,
  WhatsAppOpenedEvent,
  SupportTicketSentEvent,
} from '..'

export const HelpEventLogFactory = {

  createHelpTipClickedEvent: (data: {
    product: EventLogProductsEnum
    uid: string
    gifId: string
    platform: PlatformsEnum
    language: LanguageCodesEnum
    deviceLanguage: string
  }): HelpTipClickedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.HELP_TIP_CLICKED,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    gifId: data.gifId,
    platform: data.platform,
    language: data.language,
    deviceLanguage: data.deviceLanguage,
  }),

  createFaqClickedEvent: (data: {
    product: EventLogProductsEnum
    uid: string
    email: string
    faqId: string
    platform: PlatformsEnum
    language: LanguageCodesEnum
    deviceId: string
    deviceLanguage: string
  }): FaqClickedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.FAQ_CLICKED,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid || null,
    email: data.email || null,
    faqId: data.faqId,
    platform: data.platform,
    language: data.language,
    deviceId: data.deviceId,
    deviceLanguage: data.deviceLanguage,
  }),

  createWhatsAppClickedEvent: (data: {
    product: EventLogProductsEnum
    uid: string
    email: string
    ticketId: string
    platform: PlatformsEnum
    language: LanguageCodesEnum
    deviceLanguage: string
  }): WhatsAppClickedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.WHATSAPP_CLICKED,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid || null,
    email: data.email,
    ticketId: data.ticketId,
    platform: data.platform,
    language: data.language,
    deviceLanguage: data.deviceLanguage,
  }),

  createWhatsAppOpenedEvent: (data: {
    product: EventLogProductsEnum
    uid: string
    email: string
    phoneNumber: string
    ticketId: string
    countryCode: string
    countryPt: string
    countryEn: string
    platform: PlatformsEnum
    language: LanguageCodesEnum
    deviceLanguage: string
  }): WhatsAppOpenedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.WHATSAPP_OPENED,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid || null,
    email: data.email,
    ticketId: data.ticketId,
    platform: data.platform,
    language: data.language,
    deviceLanguage: data.deviceLanguage,
    phoneNumber: data.phoneNumber,
    countryCode: data.countryCode,
    countryEn: data.countryEn,
    countryPt: data.countryPt,
  }),

  createSupportTicketSentEvent: (data: {
    product: EventLogProductsEnum
    uid: string
    email: string
    phoneNumber: string,
    ticketId: string,
    language: LanguageCodesEnum
    deviceLanguage: string
  }): SupportTicketSentEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.SUPPORT_TICKET_SENT,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid || null,
    email: data.email,
    phoneNumber: data.phoneNumber || null,
    ticketId: data.ticketId,
    language: data.language,
    deviceLanguage: data.deviceLanguage,
  }),
}
