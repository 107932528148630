import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthClient, getSecondsInPast } from 'src/libs';
import { pageLinks } from 'src/app/core';
import { UserService } from '..';

@Injectable({
  providedIn: 'root'
})
export class AccountValidationGuard implements CanActivate {

  constructor(private auth: AuthClient,
    private userService: UserService,
    private router: Router,
  ) { }

  async canActivate() {
    if (!this.auth.authStateReady) {
      return false;
    }
    if (!this.auth.signedIn) {
      await this.router.navigate([pageLinks.coverScreen]);
      return false;
    }
    if (!this.auth.emailVerified) {
      const secondsInPast = getSecondsInPast(new Date(), 15);
      if (this.auth.accountCreationDate.toISOString() < secondsInPast.toISOString()) {
        await this.router.navigate([`${pageLinks.profileUpdate}/complete`]);
        return false;
      }
    }
    if (!this.userService.isProfileComplete()) {
      await this.router.navigate([`${pageLinks.profileUpdate}/update`]);
      return false;
    }
    return true;
  }
}
