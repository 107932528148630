import {
  GroupsEnum
} from '../..'

import {
  AgentsEnum,
  EventsEnum,
  EventLogProductsEnum,
  SidesEnum,
  ModuleOpenedEvent,
  ScaleOpenedEvent,
  ModuleAccessAttemptedEvent,
  TrialGrantedEvent
} from '..'

export const PsyeduEventLogFactory = {

  createModuleOpenedEvent: (data: {
    uid: string,
    moduleId: number
  }): ModuleOpenedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MODULE_OPENED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.PSYCHOEDUCATION,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    id: data.moduleId
  }),

  createScaleOpenedEvent: (data: {
    uid: string,
    scaleId: string
  }): ScaleOpenedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.SCALE_OPENED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.PSYCHOEDUCATION,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    id: data.scaleId
  }),

  createModuleAccessAttemptedEvent: (data: {
    uid: string,
    moduleId: number
  }): ModuleAccessAttemptedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.MODULE_ACCESS_ATTEMPTED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.PSYCHOEDUCATION,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    id: data.moduleId
  }),

  createTrialGrantedEvent: (data: {
    uid: string
  }): TrialGrantedEvent => ({
    agent: AgentsEnum.SYSTEM,
    event: EventsEnum.TRIAL_GRANTED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.PSYCHOEDUCATION,
    side: SidesEnum.SERVER,
    timestamp: new Date(),
    uid: data.uid
  }),
}
