import { ErrorHandler, Injectable, NgZone } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private zone: NgZone
  ) {}

  handleError(error: any) {
    // Check if it's an error from an HTTP response
    this.zone.run(() => {
      document.write(new Error(error).stack)
    });
    console.error('Error from global error handler', error);
  }
}