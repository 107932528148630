import {
  GroupsEnum,
  CorporateDepartmentDBDocument,
  DepartmentAccessRequestDBDocument,
  UserDBDocument
} from '../..'

import {
  AgentsEnum,
  EventsEnum,
  EventLogProductsEnum,
  SidesEnum,
  DepartmentAccessRequestEvent,
  DepartmentInterconnectionEvent,
  DepartmentInterconnectionDBDocument
} from '..'

export const CorpEventLogFactory = {

  createAccessRequestSentEvent: (data: {
    accessRequestId: string,
    department: CorporateDepartmentDBDocument,
    employee: UserDBDocument
  }): DepartmentAccessRequestEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.ACCESS_REQUEST_SENT,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.CORP_DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.employee.uid,
    departmentId: data.department.id,
    accessRequestId: data.accessRequestId,
    corporationId: data.department.corporationId
  }),

  createAccessRequestAcceptedEvent: (data: {
    accessRequest: DepartmentAccessRequestDBDocument
  }): DepartmentAccessRequestEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.ACCESS_REQUEST_ACCEPTED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.CORP_DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid:  data.accessRequest.uid,
    departmentId:  data.accessRequest.departmentId,
    accessRequestId:  data.accessRequest.id,
    corporationId: data.accessRequest.corporationId
  }),

  createAccessRequestRefusedEvent: (data: {
    accessRequest: DepartmentAccessRequestDBDocument
  }): DepartmentAccessRequestEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.ACCESS_REQUEST_REFUSED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.CORP_DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.accessRequest.uid,
    departmentId: data.accessRequest.departmentId,
    accessRequestId: data.accessRequest.id,
    corporationId: data.accessRequest.corporationId
  }),

  createAccessRequestCancelledEvent: (data: {
    accessRequest: DepartmentAccessRequestDBDocument
  }): DepartmentAccessRequestEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.ACCESS_REQUEST_CANCELLED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.CORP_DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    departmentId: data.accessRequest.departmentId,
    uid: data.accessRequest.uid,
    accessRequestId: data.accessRequest.id,
    corporationId: data.accessRequest.corporationId
  }),

  createDepartmentInterconnectionCreatedEvent: (data: {
    accessRequest: DepartmentAccessRequestDBDocument,
    interconnectionId: string
  }): DepartmentInterconnectionEvent => ({
    agent: AgentsEnum.SYSTEM,
    event: EventsEnum.INTERCONNECTION_CREATED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.CORP_DASHBOARD,
    side: SidesEnum.SERVER,
    timestamp: new Date(),
    employeeId: data.accessRequest.uid,
    departmentId: data.accessRequest.departmentId,
    interconnectionId: data.interconnectionId,
    corporationId: data.accessRequest.corporationId
  }),

  createDepartmentInterconnectionTerminatedEvent: (data: {
    interconnection: DepartmentInterconnectionDBDocument
  }): DepartmentInterconnectionEvent => ({
    agent: AgentsEnum.SYSTEM,
    event: EventsEnum.INTERCONNECTION_TERMINATED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.CORP_DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    interconnectionId: data.interconnection.id!,
    employeeId: data.interconnection.employeeId!,
    departmentId: data.interconnection.departmentId!,
    corporationId: data.interconnection.corporationId!
  })
}