import {
  CancelRequestToAccessDepartmentRequest,
  CancelTherapistInvitationRequest,
  ChangePasswordRequest,
  CreateUserRequest,
  GetUserViewRequest,
  InviteTherapistRequest,
  RequestAccessToDepartmentRequest,
  SendEmailVerificationActionLinkRequest,
  QuitDepartmentTrainingRequest,
  StopSharingWithTherapistRequest,
  UpdateUserProfileRequest,
} from './request-types'

/** @deprecated */
export enum AuthenticatedRequestTypesEnum {
  /** @deprecated */
  GET_USER = 'get-user', // TODO remove AFTER 3.1.2

  CREATE_USER = 'create-user',
  GET_USER_VIEW = 'get-user-view',
  UPDATE_USER_PROFILE = 'update-user-profile',

  INVITE_THERAPIST = 'invite-therapist',
  CANCEL_THERAPIST_INVITATION = 'cancel-therapist-invitation',
  STOP_SHARING_WITH_THERAPIST = 'stop-sharing-with-therapist',

  REQUEST_ACCESS = 'request-access-to-department',
  CANCEL_REQUEST_TO_ACCESS_DEPARTMENT = 'cancel-request-to-access-department',
  QUIT_DEPARTMENT_TRAINING = 'quit-department-training',

  SEND_EMAIL_VERIFICATION_ACTION_LINK = 'send-email-verification-action-link'
}

/** @deprecated */
export type AuthenticatedCFRequest = {
  type: AuthenticatedRequestTypesEnum
  request?: CreateUserRequest
    | GetUserViewRequest
    | UpdateUserProfileRequest
    | ChangePasswordRequest
    | InviteTherapistRequest
    | CancelTherapistInvitationRequest
    | StopSharingWithTherapistRequest
    | RequestAccessToDepartmentRequest
    | CancelRequestToAccessDepartmentRequest
    | QuitDepartmentTrainingRequest
    | SendEmailVerificationActionLinkRequest
}
