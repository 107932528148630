import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FirebaseInitializer, AuthClient, FirestoreClient, RtdbClient, CloudFunctionsClient } from 'src/libs';
import { ConfigService, DeviceService, LanguageService, LocalStorageService, createTranslateHttpLoader, supportedLocales } from './core';
import { GlobalErrorModule } from './shared/errors/global-error.module';

const initializeApp = (
  deviceService: DeviceService,
  firebaseInitializer: FirebaseInitializer,
  localStorageService: LocalStorageService,
  languageService: LanguageService
) => async () => {
  await deviceService.initializeDeviceInfo(ConfigService.isProduction());
  firebaseInitializer.initialize(ConfigService.getFirebase());
  await localStorageService.initializeStorage();
  await languageService.setInitialLanguage(supportedLocales);
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateHttpLoader(),
        deps: [HttpClient]
      },
      isolate: false
    }),
    IonicModule.forRoot({}),
    IonicStorageModule.forRoot({
      storeName: 'cognipanel'
    }),
    GlobalErrorModule,
    AppRoutingModule,
  ],
  providers: [
    FirebaseInitializer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: APP_INITIALIZER, deps: [DeviceService, FirebaseInitializer, LocalStorageService, LanguageService], useFactory: initializeApp, multi: true },
    { provide: AuthClient, deps: [FirebaseInitializer] },
    { provide: FirestoreClient, deps: [FirebaseInitializer] },
    { provide: RtdbClient, deps: [FirebaseInitializer] },
    { provide: CloudFunctionsClient, deps: [FirebaseInitializer] },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
