import { DeviceInfo, GroupsEnum, LanguageCodesEnum } from '../..'
import { GoToDashboardClikedEvent, GoToPageClikedEvent, MenuHelpOthersClikedEvent, ShareCogniCodeClikedEvent } from './event-logs.types'
import { AgentsEnum, EventLogProductsEnum, EventPageIdsEnum, EventsEnum, SidesEnum } from './event-logs.enums'

export const MenuEventLogFactory = {
  createHelpOthersClickedEvent: (data : {
    product: EventLogProductsEnum
    uid: string
    language: LanguageCodesEnum
    deviceLanguage: string
  }): MenuHelpOthersClikedEvent => ({
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.MENU_HELP_OTHERS_CLIKED,
    timestamp: new Date(),
    uid: data.uid,
    language: data.language,
    deviceLanguage: data.deviceLanguage,
  }),

  createShareCogniCodeClickedEvent: (data : {
    product: EventLogProductsEnum
    uid: string
    cognicode: string
    device: DeviceInfo
    language: LanguageCodesEnum
  }): ShareCogniCodeClikedEvent => ({
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.MENU_SHARE_COGNI_CODE_CLIKED,
    timestamp: new Date(),
    uid: data.uid,
    cognicode: data.cognicode,
    deviceId: data.device.deviceUuid,
    os: data.device.os,
    osVersion: data.device.osVersion,
    platform: data.device.platform,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type,
    language: data.language,
    deviceLanguage: data.device.languageCode,
  }),

  createGoToDashboardClickedEvent: (data : {
    product: EventLogProductsEnum
    uid: string
    cognicode: string
    device: DeviceInfo
    language: LanguageCodesEnum
  }): GoToDashboardClikedEvent => ({
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.MENU_GO_TO_DASHBOARD_CLIKED,
    timestamp: new Date(),
    uid: data.uid,
    cognicode: data.cognicode,
    deviceId: data.device.deviceUuid,
    os: data.device.os,
    osVersion: data.device.osVersion,
    platform: data.device.platform,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type,
    language: data.language,
    deviceLanguage: data.device.languageCode,
  }),

  createGoToMatchmakingClickedEvent: (data : {
    product: EventLogProductsEnum
    uid: string
    cognicode: string
    device: DeviceInfo
    language: LanguageCodesEnum
  }): GoToDashboardClikedEvent => ({
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.MENU_GO_TO_MATCHMAKING_CLIKED,
    timestamp: new Date(),
    uid: data.uid,
    cognicode: data.cognicode,
    deviceId: data.device.deviceUuid,
    os: data.device.os,
    osVersion: data.device.osVersion,
    platform: data.device.platform,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type,
    language: data.language,
    deviceLanguage: data.device.languageCode,
  }),

  createGoToPageClickedEvent: (data : {
    product: EventLogProductsEnum
    uid: string
    pageId: EventPageIdsEnum
    language: LanguageCodesEnum
    deviceLanguage: string
  }): GoToPageClikedEvent => ({
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    agent: AgentsEnum.USER,
    event: EventsEnum.MENU_GO_TO_PAGE_CLIKED,
    timestamp: new Date(),
    uid: data.uid,
    language: data.language,
    deviceLanguage: data.deviceLanguage,
    pageId: data.pageId
  }),
}
