import { Injectable } from '@angular/core';
import {
  AuthClient,
  EventLog,
  FirestoreClient,
  AccountEventLogFactory,
  AuthenticationEventLogFactory,
  EventLogProductsEnum,
  ScheduleInterviewEventLogFactory,
  SupportTicketDBDocument,
  HelpEventLogFactory,
  ContentEventLogFactory,
  EventPageIdsEnum
} from 'src/libs';
import { DeviceService, ConfigService, LanguageService } from '.';

@Injectable({
  providedIn: 'root'
})
export class EventLogService {

  private readonly eventLogProductsEnum: EventLogProductsEnum;
  private readonly systemId = 'system';

  constructor(private auth: AuthClient,
    private firestore: FirestoreClient,
    private languageService: LanguageService,
    private deviceService: DeviceService
  ) {
    this.eventLogProductsEnum = ConfigService.getEventLogProductsEnum();
  }

  insertSignUpAttemptEvent(email: string, uid: string | null, errorReason?: string) {
    // uid comes from the caller because the user is not signed in automatically
    const id = this.getUidForEventLog(uid);
    const eventlog = AuthenticationEventLogFactory.createSignUpAttemptEvent({
      deviceId: this.deviceService.deviceUuid,
      email,
      language: this.languageService.currentLanguage,
      product: this.eventLogProductsEnum,
      uid,
      errorReason,
    })
    return this.insertEventLog(id, eventlog);
  }

  insertSignInAttemptEvent(email: string, errorReason?: string) {
    // if signin was successful, this.auth.uid is available
    const id =  this.getUidForEventLog(this.auth.uid);
    const eventlog = AuthenticationEventLogFactory.createSignInAttemptEvent({
      deviceId: this.deviceService.deviceUuid,
      product: this.eventLogProductsEnum,
      email,
      uid: id,
      errorReason,
    });
    return this.insertEventLog(id, eventlog);
  }

  insertSignOutEvent(uid: string) {
    // uid comes from the caller because the event is after signout
    const eventlog = AuthenticationEventLogFactory.createSignOutEvent({
      product: this.eventLogProductsEnum,
      uid,
    });
    return this.insertEventLog(uid, eventlog);
  }

  insertAuthenticatedEvent() {
    const eventlog = AuthenticationEventLogFactory.createAuthenticatedEvent({
      uid: this.auth.uid,
      product: this.eventLogProductsEnum,
      device: this.deviceService.device,
    });
    return this.insertEventLog(this.auth.uid, eventlog);
  }

  insertPasswordRecoveryAttemptedEvent(email: string, errorReason?: string) {
    const id =  this.getUidForEventLog();
    const eventlog = AccountEventLogFactory.createPasswordRecoveryAttemptedEvent({
      deviceId: this.deviceService.deviceUuid,
      product: this.eventLogProductsEnum,
      email,
      errorReason,
    });
    return this.insertEventLog(id, eventlog);
  }

  insertPasswordResetEvent(email: string) {
    const id =  this.getUidForEventLog();
    const eventlog = AccountEventLogFactory.createPasswordResetEvent({
      deviceId: this.deviceService.deviceUuid,
      product: this.eventLogProductsEnum,
      email,
    });
    return this.insertEventLog(id, eventlog);
  }

  insertScheduleInterviewSeenEvent(
    option: number
  ) {
    const eventlog = ScheduleInterviewEventLogFactory.createInviationSeenEvent({
      uid: this.auth.uid,
      option
    });
    return this.insertEventLog(this.auth.uid, eventlog);
  }

  insertScheduleInterviewClickedEvent(
    option: number
  ) {
    const eventlog = ScheduleInterviewEventLogFactory.createInviationClickedEvent({
      uid: this.auth.uid,
      option
    });
    return this.insertEventLog(this.auth.uid, eventlog);
  }

  insertScheduleInterviewRejectedEvent(
    option: number
  ) {
    const eventlog = ScheduleInterviewEventLogFactory.createInviationRejectedEvent({
      uid: this.auth.uid,
      option
    });
    return this.insertEventLog(this.auth.uid, eventlog);
  }

  insertSupportTicketSentEvent(
    supportTicket: SupportTicketDBDocument
  ) {
    const id =  this.getUidForEventLog(this.auth.uid);
    const eventlog = HelpEventLogFactory.createSupportTicketSentEvent({
      product: this.eventLogProductsEnum,
      uid: this.auth.uid,
      email: supportTicket.email,
      ticketId: supportTicket.id,
      phoneNumber: supportTicket.phoneNumber?.internationalFormat,
      deviceLanguage: this.deviceService.deviceLanguage,
      language: this.languageService.currentLanguage,
    });
    return this.insertEventLog(id, eventlog);
  }

  insertMuralTileClickedEvent(
    tileId: string
  ) {
    const id =  this.getUidForEventLog(this.auth.uid);
    const eventlog = ContentEventLogFactory.createMuralTileClickedEvent({
      uid: id,
      tileId
    });
    return this.insertEventLog(id, eventlog);
  }

  insertContentAccessedEvent(
    contentId: string
  ) {
    const id =  this.getUidForEventLog(this.auth.uid);
    const eventlog = ContentEventLogFactory.createContentAccessedEvent({
      uid: id,
      product: EventLogProductsEnum.DASHBOARD,
      deviceLanguage: this.deviceService.deviceLanguage,
      language: this.languageService.currentLanguage,
      contentId
    });
    return this.insertEventLog(id, eventlog);
  }

  createPageAccessedEvent(
    pageId: EventPageIdsEnum
  ) {
    const id = this.getUidForEventLog(this.auth.uid);
    const eventlog = ContentEventLogFactory.createPageAccessedEvent({
      uid: id,
      product: EventLogProductsEnum.DASHBOARD,
      deviceLanguage: this.deviceService.deviceLanguage,
      language: this.languageService.currentLanguage,
      pageId: pageId
    });
    return this.insertEventLog(id, eventlog);
  }

  private getUidForEventLog(uid?: string) {
    return uid ?? this.systemId;
  }

  private insertEventLog(userId: string, attributes: EventLog) {
    const evenlogId = this.getIdForNewEventLog(userId);
    return this.firestore.insertDoc(this.getEventLogsCollRef(userId), evenlogId, attributes);
  }

  private getIdForNewEventLog(uid: string) {
    return this.firestore.newDocId(this.getEventLogsCollRef(uid))
  }
 
  private getEventLogsCollRef(uid: string) {
    return this.firestore.collRef(`${this.eventLogsCollId}/${uid}/${this.eventlogsCollGroupId}`)
  }

  private eventLogsCollId = '/eventLogs'
  // collection groups MUST NOT contain a leading '/'
  private eventlogsCollGroupId = 'elogs'
}
