import { ErrorsEnum, throwError } from '..'

export function assertNonNullish<TValue>(
  value: TValue,
  functionName?: string,
  paramName?: string
): asserts value is NonNullable<TValue> {
  if (value === null || value === undefined) {
    if (functionName) {
      throwError({
        error: ErrorsEnum.NULLISH_PARAMETER,
        message: `${functionName} - ${paramName} is nullish`
      })
    }
    throw new Error(ErrorsEnum.NULLISH_PARAMETER)
  }
}

export function assertMandatoryParamsAreNotMissing(
  urlParams: Record<string, unknown>,
  params: string[]
) {
  const missingParams = params.filter(
    (paramName: string) =>
      !Object.prototype.hasOwnProperty.call(urlParams, paramName)
  )

  if (missingParams.length !== 0) {
    throwError({
      error: ErrorsEnum.MISSING_PARAMETERS,
      params: missingParams
    })
  }
}
