import {
  AcceptRequestAccessToDepartmentRequest,
  ArchiveEmployeeRequest,
  BatchInviteEmployeesRequest,
  ChangePasswordRequest,
  CreateUserRequest,
  GetUserViewRequest,
  RefuseRequestAccessToDepartmentRequest,
  ReinviteEmployeeRequest,
  SendEmailVerificationActionLinkRequest,
  UpdateUserProfileRequest
} from './request-types'

export enum CorpDashAuthenticatedRequestTypesEnum {
  CREATE_USER = 'create-user',
  GET_USER_VIEW = 'get-user-view',
  UPDATE_USER_PROFILE = 'update-user-profile',

  BATCH_INVITE_EMPLOYEES = 'batch-invite-employees',
  ACCEPT_REQUEST_TO_ACCESS_DEPARTMENT = 'accept-request-to-access-department',
  REFUSE_REQUEST_TO_ACCESS_DEPARTMENT = 'refuse-request-to-access-department',
  ARCHIVE_EMPLOYEE = 'archive-employee',
  REINVITE_EMPLOYEE = 'reinvite-employee',

  SEND_EMAIL_VERIFICATION_ACTION_LINK = 'send-email-verification-action-link',
  CHANGE_PASSWORD = 'change-password'
}

export type CorpDashAuthenticatedCFRequest = {
  type: CorpDashAuthenticatedRequestTypesEnum
  request?: CreateUserRequest
    | GetUserViewRequest
    | UpdateUserProfileRequest
    | ChangePasswordRequest
    | AcceptRequestAccessToDepartmentRequest
    | RefuseRequestAccessToDepartmentRequest
    | ArchiveEmployeeRequest
    | ReinviteEmployeeRequest
    | BatchInviteEmployeesRequest
    | SendEmailVerificationActionLinkRequest
}
