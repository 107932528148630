import {
  LanguageCodesEnum,
  GroupsEnum,
  DeviceInfo
} from '../..'

import {
  SignupAttemptedEvent,
  SigninAttemptedEvent,
  SignedOutEvent,
  AgentsEnum,
  EventsEnum,
  EventLogProductsEnum,
  SidesEnum,
  ResultsEnum,
  AuthenticatedEvent
} from '..'

export const AuthenticationEventLogFactory = {

  createSignUpAttemptEvent: (data: {
    uid: string,
    email: string,
    language: LanguageCodesEnum,
    product: EventLogProductsEnum,
    deviceId?: string,
    errorReason?: string
  }): SignupAttemptedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.SIGNUP_ATTEMPTED,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid || null,
    email: data.email.toLowerCase(),
    language: data.language,
    deviceId: data.deviceId || null,
    result: data.errorReason ? ResultsEnum.ERROR : ResultsEnum.OK,
    errorReason: data.errorReason || null
  }),

  createSignInAttemptEvent: (data: {
    uid?: string,
    email: string,
    product: EventLogProductsEnum,
    deviceId?: string,
    errorReason?: string
  }): SigninAttemptedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.SIGNIN_ATTEMPTED,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid || null,
    email: data.email.toLowerCase(),
    deviceId: data.deviceId || null,
    result: data.errorReason ? ResultsEnum.ERROR : ResultsEnum.OK,
    errorReason: data.errorReason || null
  }),

  createSignOutEvent: (data: {
    product: EventLogProductsEnum,
    uid: string
  }): SignedOutEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.SIGNED_OUT,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid
  }),

  createAuthenticatedEvent: (data: {
    product: EventLogProductsEnum,
    uid: string,
    device: DeviceInfo
  }): AuthenticatedEvent => ({
    agent: AgentsEnum.USER,
    event: EventsEnum.AUTHENTICATED,
    group: GroupsEnum.COGNI,
    product: data.product,
    side: SidesEnum.CLIENT_UX,
    timestamp: new Date(),
    uid: data.uid,
    deviceId: data.device.deviceUuid || null,
    os: data.device.os || null,
    osVersion: data.device.osVersion || null,
    platform: data.device.platform || null,
    appVersion: data.device.appVersion || null,
    deviceType: data.device.type || null
  })
}
